import React, { useState, useEffect } from 'react';
import PayPal from './paypal';

const PayPalPayment = () => {
    const [payamount, setAmount] = useState("");
    const [billNo, setBillNo] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [inputFocusBillNumber, setInputFocusBillNumber ] = useState(false);
    const [inputFocusPayAmount, setInputFocusPayAmount ] = useState(false);
    const [inputFocusPhoneNumber, setInputFocusPhoneNumber ] = useState(false);
    const [testMode, setTestMode] = useState(false);
    const [paymentRecevied, setPaymentRecevied] = useState(false);
    
    const handleChangeBillNumber = (event) => { setBillNo(event.target.value.replace(/\D/g, ''))};
    const handlePhoneNumber = (event) => { setPhoneNo(event.target.value.replace(/\D/g, '')) };
    const handleChangePayAmount = (event) => { setAmount(event.target.value.replace(/[^0-9.]/g, '')); };

    useEffect(( ) => {
        const urlSearchString = window.location.search;
        const params = new URLSearchParams(urlSearchString);
        if (params.get('cidebug')) { 
            setTestMode(true)
        }
        }, 
        [ ]
    );

    const handleFormFocus = (obj) => {
        if (testMode) { console.log(`Go Focus on ${obj.target.name}`); }
        if (obj && obj.target && obj.target.name === 'billNumber') { setInputFocusBillNumber(true) }
        if (obj && obj.target && obj.target.name === 'payAmount') { setInputFocusPayAmount(true) }
        if (obj && obj.target && obj.target.name === 'phoneNumber') { setInputFocusPhoneNumber(true) }
    }
    const handleFormFocusLoss = (obj) => {
        if (testMode) { console.log(`Lost Focus on ${obj.target.name}`); }
        if (obj && obj.target && obj.target.name === 'billNumber') { setInputFocusBillNumber(false) }
        if (obj && obj.target && obj.target.name === 'payAmount') { setInputFocusPayAmount(false) }
        if (obj && obj.target && obj.target.name === 'phoneNumber') { setInputFocusPhoneNumber(false) }
    }
    const handlePaymentChange = () => {
        setPaymentRecevied(true);
    }

return (
    <div>
        {!paymentRecevied && <div class="form-group mb">
            <div className={`input-wrap ${inputFocusBillNumber || billNo ? 'has-content': ''}`}>
                <label class="input-label">Account # (Printed on bill you received via US Postal Service):</label> 
                <input 
                    title="Account #" 
                    class="form-control" 
                    name="billNumber" 
                    required="required" 
                    onClickCapture={handleFormFocus} 
                    onBlur={handleFormFocusLoss} 
                    onChange={handleChangeBillNumber}
                    value={billNo}
                /> 
            </div>
            <br></br>
            <div className={`input-wrap ${inputFocusPhoneNumber || phoneNo ? 'has-content': ''}`}>
                <label class="input-label">Phone # (Number we can call you if there any questions):</label> 
                {/* <PhoneInput 
                    class="form-control" 
                    name="phoneNumber"
                    value={phoneNo} 
                    onChange={handlePhoneNumber}
                    onClickCapture={handleFormFocus} 
                    onBlur={handleFormFocusLoss} 
                /> */}
                <input 
                    title="Phone Number" 
                    class="form-control" 
                    name="phoneNumber" 
                    required="required" 
                    onClickCapture={handleFormFocus} 
                    onBlur={handleFormFocusLoss} 
                    onChange={handlePhoneNumber}
                    value={phoneNo}
                /> 
            </div>
            <br></br>
            <div className={`input-wrap ${inputFocusPayAmount || payamount ? 'has-content': ''}`}>
                <label class="input-label">Amount To Pay</label> 
                <input 
                    title="Amount To Pay" 
                    class="form-control" 
                    name="payAmount" 
                    required="required" 
                    onClickCapture={handleFormFocus} 
                    onBlur={handleFormFocusLoss} 
                    onChange={handleChangePayAmount}
                    value={payamount}
                /> 
            </div>
        </div>
        }

        { 
            <PayPal accountNumber={billNo} phoneNumber={phoneNo} payAmount={payamount} testMode={testMode} paymentReceived={handlePaymentChange}></PayPal>
        }
    </div>
    );
};  

export default function PayMyBill() {
    return PayPalPayment()
} 
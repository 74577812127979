import React, { useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import SendEmail from './azureEmail';

const PAYPAL_INIT_OPT_PROD = {
    'client-id': 'AZQzhweX8tylaTBElS7V3AgPFh03Tm1PRY68ymW3H7sYebr5xDkw-ZUji5tIIzozSu14iw8qjpQGuoig',
    'currency': 'USD',
    'disable-funding': 'paylater',
    'data-sdk-integration-source': 'integrationbuilder_sc',
};
const PAYPAL_INIT_OPT_DEV = {
    'client-id': 'AdVlCuucf20lnU7Y66CtTrAT6eHotDcQXSU4hiKVErJlpU2lIj6e5GqfnagWxYl9al2O7lZi-ZRQaww5',
    'currency': 'USD',
    'disable-funding': 'paylater',
    'data-sdk-integration-source': 'integrationbuilder_sc',
};
let payThis=0;
let contactPhone='';
let patientAccountNo = '';
let confNumber = '';

export default function PayPal({accountNumber, phoneNumber, payAmount, testMode=false, paymentReceived}) {
    payThis=payAmount;
    contactPhone = phoneNumber;
    patientAccountNo = accountNumber;
    const [paymentConfirmation, setPaymentConfirmation] = useState(null);
    const [payPalStatus, setPayPalStatus] = useState('Capturing');
    const [emailError, setEmailError] = useState(false);

    const collectMoney = (data, actions) => {
        return actions.order.capture()
        .then(a => {
            setPaymentConfirmation(a);
            confNumber=a.id;
            SendEmail(
                "noreply@amazingkids.org", 
                "Alert-Internet-BillPay@tciop.onmicrosoft.com", 
                "Internet Bill Payment Received", 
                `${testMode ? "<h5>SANDBOX - DO NOT PROCESS</h5>" : ""}
                <p>CI Received A Payment towards a medical bill.</p>
                <label>Account #: </lablel><span>${patientAccountNo}</span><br />
                <label>Contact Phone Number: </label><span>${contactPhone}</span><br />
                <label>Paypal Transaction ID: </label><span>${a.id}</span><br />
                <label>Amount Paid: </label><span>$${payThis}</span><br /><br />
                <h6>Paypal additional info</h6>
                <label>Name: </label><span>${a.payer.name.given_name ? a.payer.name.given_name : "NA"} ${a.payer.name.surname ? a.payer.name.surname : "NA"}</span><br />
                <label>Email: </label><span>${a.payer.email_address ? a.payer.email_address : "NA"}</span><br />
                `)
                .catch(e => {
                    setEmailError(true);
                    console.error(emailError);
                })
                .then(a => {
                    if (testMode) {
                        console.debug('Email result')
                        console.debug(a);
                    }
                });
        })
        .then(b => {
            setPayPalStatus('Paid');
            paymentReceived();
            setPaymentConfirmation(true);
        });
    };

    const createPayPalOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [{description: 'Therapy Bill Pay', amount: {currency_code: 'USD', value: payThis}}]
        })
    };

    const goHome = () => {
        window.location.replace('https://www.amazingkids.org');
    }

    return (
      <div>
        {testMode && <div style={{"background-color": "steelblue", "textAlign": "center", "padding": "5px", "margin": "15px"}}><p>SANDBOX MODE</p></div>}
        {testMode && payPalStatus !== 'Paid' &&
            <PayPalScriptProvider options={PAYPAL_INIT_OPT_DEV}>
            <PayPalButtons  disabled={((!payAmount || !accountNumber || !phoneNumber) && paymentConfirmation == null )}
                createOrder={createPayPalOrder}
                onApprove={collectMoney} 
                style={{
                    layout: 'horizontal',
                    color: 'blue',
                    shape: 'pill',
                }}
            />
        </PayPalScriptProvider>
        }
        {!testMode && payPalStatus !== 'Paid' && 
            <PayPalScriptProvider options={PAYPAL_INIT_OPT_PROD}>
            <PayPalButtons  disabled={((!payAmount || !accountNumber || !phoneNumber) && paymentConfirmation == null )}
                createOrder={createPayPalOrder}
                onApprove={collectMoney} 
                style={{
                    layout: 'horizontal',
                    color: 'blue',
                    shape: 'pill',
                }}
            />
        </PayPalScriptProvider>
        }
        { payPalStatus === 'Paid' && 
            <div>
                <h5>Thank you for your payment of ${payAmount}, your confirmation number is <i>{confNumber}</i></h5>
                <p>
                    Please retain this confirmation ID as it can assist in correcting any payment posting issues.
                    You should also receive a confirmation email from PayPal with this transaction detail.
                </p>
                <button className='btn btn-default' onClick={goHome}>Back to Amazing Kids website</button>
           </div>
        }

      </div>
    );
  }
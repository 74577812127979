const { EmailClient } = require("@azure/communication-email");

const connectionString = "endpoint=https://cimarketing-communicationservices.unitedstates.communication.azure.com/;accesskey=e5dSmt8Epbc0gDAzspAckoPLXZ4TX+mnZivUHb4/2/Yhe+22+3EzDCvfZ3zlSq7ZzvVTMjU18nswSJWoHtEN6g==";
const emailClient = new EmailClient(connectionString);

const emailPatientAccounting = async (Subject, Message, From, To) => {
    const message = {
        senderAddress: "DoNotReply@efe161b8-9bb0-498c-ae61-c2201a9b0cf0.azurecomm.net",
        recipients: { to: [ { address: To } ] },
        content: {
            subject: Subject,
            html: Message
        }
    };
    const poller = await emailClient.beginSend(message);
    const response = await poller.pollUntilDone();
    return response;
}

export default function SendEmail(from, to, subject, message) {
    return emailPatientAccounting(subject, message, from, to);
}